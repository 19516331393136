import React from 'react'

import * as analytics from './ga4'
import { useRouter } from 'next/router'

export function useAnalytics() {
  React.useEffect(() => {
    analytics.init()
  }, [])

  const router = useRouter()

  analytics.sendPageview(router.asPath)
}

export default useAnalytics
