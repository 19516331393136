import {
  type ProfileId,
  ReportCreationService,
  type Source,
} from '@/lib/api-client/generated'
import { getApiGeneralError, isGeneralError } from '@/utils/api'
import useCustomAsyncFn from './use-custom-async-fn'

type PostReportAdd = {
  source: Source
  id?: ProfileId
  unloaded?: boolean
}

/**
 * Service for adding profile to report creation.
 *
 * ReportCreationService.postReportAdd
 * useCustomAsyncFn implementation
 */
const useAddSourceToReportService = (reportId: string) => {
  const state = useCustomAsyncFn(
    async ({ source, unloaded, id }: PostReportAdd) => {
      try {
        const result = await ReportCreationService.postReportAdd(
          reportId,
          source,
          { id, unloaded },
        )

        if (isGeneralError(result)) {
          throw result
        }

        return result
      } catch (e) {
        throw getApiGeneralError("can't add profile source to report", e)
      }
    },
    [],
  )

  return state
}

export default useAddSourceToReportService
