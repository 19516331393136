import { useMount } from 'react-use'
import { useGetInterestService } from './api'
import type { ReportSingleInterest, Source } from '../api-client/generated'

const useInterest = (
  reportId: string,
  source: Source,
  interestId: string,
  initialInterest?: ReportSingleInterest,
) => {
  const [state, getInterest] = useGetInterestService()

  useMount(() => {
    if (initialInterest) {
      return
    }

    getInterest(reportId, source, interestId)
  })

  return { value: initialInterest || state.value, loading: state.loading }
}

export default useInterest
