import { useTranslation } from 'react-i18next'

import { Loading } from '../Loading'

import styles from './CancelableLoader.module.scss'

type CancelableLoaderProps = {
  onCancel: () => void
}
const CancelableLoader: React.FC<CancelableLoaderProps> = ({ onCancel }) => {
  const { t } = useTranslation()

  return (
    <div>
      <Loading />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className={styles.cancelFollowsLoadingBtn} onClick={onCancel}>
        {t('Cancel')}
      </a>
    </div>
  )
}

export default CancelableLoader
