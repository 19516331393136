import useReportStore from './store'

export const useRedzoneCategoryMapSelector = () =>
  useReportStore(
    (store) =>
      [
        store.redzoneCategorySourceMap,
        store.setRedzoneCategorySourceMap,
      ] as const,
  )

export const useReportMapSelector = (reportId: string) =>
  useReportStore(
    (store) => [store.reportMap[reportId], store.setReport] as const,
  )

export const useSummaryStateSelector = (reportId: string) =>
  useReportStore(
    (store) => [store.reportMap[reportId]?.summary, store.setSummary] as const,
  )
