import React from 'react'
import type {
  InterestId,
  InterestProfiles,
  Source,
} from '../api-client/generated'
import { useGetInterestProfilesService } from './api'

const useInterestProfileList = (
  reportId: string,
  interestId: InterestId,
  source: Source,
  initialProfiles: InterestProfiles[],
) => {
  const [profileList, setProfileList] = React.useState(initialProfiles)

  const [{ loading }, getInterestProfilesPage] = useGetInterestProfilesService(
    reportId,
    source,
    interestId,
  )

  const handleAddProfileItems = React.useCallback(
    (offset: number) => async () => {
      const extraProfiles = await getInterestProfilesPage(offset)

      setProfileList((prev) => {
        const prevSourceIndex = prev.findIndex(
          ({ sourceProfile }) => sourceProfile.source === source,
        )

        if (prevSourceIndex === -1) {
          return prev
        }

        return [
          ...prev.map((profile, index) => {
            if (index !== prevSourceIndex) {
              return profile
            }

            return {
              ...profile,
              hasMore: extraProfiles.hasMore,
              items: [...profile.items, ...(extraProfiles?.items || [])],
            }
          }),
        ]
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return { profileList, loading, handleAddProfileItems }
}

export default useInterestProfileList
