import useErrorEffect from '../use-error-effect'
import {
  DataReceivingService,
  type InterestId,
  type Source,
} from '@/lib/api-client/generated'
import { getApiGeneralError, isGeneralError } from '@/utils/api'
import useCustomAsyncFn from './use-custom-async-fn'

/**
 * Service for receiving and paging for profiles on which the specific interest of a particular social network is based
 *
 * DataReceivingService.getReportProfiles
 * useCustomAsyncFn implementation
 */
const useGetInterestProfilesService = (
  reportId: string,
  source: Source,
  interestId: InterestId,
) => {
  const state = useCustomAsyncFn(async (offset?: number, limit: number = 8) => {
    try {
      const result = await DataReceivingService.getReportProfiles(
        reportId,
        source,
        interestId,
        offset,
        limit,
      )

      if (isGeneralError(result)) {
        throw result
      }

      return result
    } catch (e) {
      throw getApiGeneralError("can't get interest profiles from the server", e)
    }
  }, [])

  useErrorEffect(state[0].error, true)

  return state
}

export default useGetInterestProfilesService
