import { useRouter } from 'next/router'
import React from 'react'

const useBrowserBackButtonEffect = (cb: () => void) => {
  const router = useRouter()

  React.useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        cb()
      }

      return true
    })

    return () => {
      router.beforePopState(() => true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])
}

export default useBrowserBackButtonEffect
