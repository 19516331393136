import React from 'react'
import Image from 'next/image'
import placeholder from 'src/assets/images/profile-placeholder.svg'
import { ImageProps } from 'next/dist/shared/lib/get-img-props'

type Props = ImageProps & {
  fallback?: string
}

const FallBackImage = ({
  fallback = placeholder,
  src,
  alt,
  width,
  height,
  ...props
}: Props) => {
  const [error, setError] = React.useState(false)

  React.useEffect(() => {
    setError(false)
  }, [src])

  const onError = React.useCallback(() => {
    setError(true)
  }, [])

  return (
    <Image
      alt={alt}
      onError={onError}
      src={error ? fallback : src}
      width={width}
      height={height}
      {...props}
    />
  )
}

export default FallBackImage
