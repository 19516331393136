import useErrorEffect from '../use-error-effect'
import { ReportCreationService } from '@/lib/api-client/generated'
import { getApiGeneralError, isGeneralError } from '@/utils/api'
import useCustomAsyncFn from './use-custom-async-fn'

/**
 * Service
 *
 * ReportCreationService.postReportNew
 * useCustomAsyncFn implementation
 */
const useCreateReportService = () => {
  const state = useCustomAsyncFn(async () => {
    try {
      const result = await ReportCreationService.postReportNew()

      if (isGeneralError(result)) {
        throw result
      }

      return result
    } catch (e) {
      throw getApiGeneralError("can't create new report", e)
    }
  }, [])

  useErrorEffect(state[0].error)

  return state
}

export default useCreateReportService
