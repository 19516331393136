/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddResult } from '../models/AddResult'
import type { GeneralError } from '../models/GeneralError'
import type { ProfileId } from '../models/ProfileId'
import type { SearchProfilesPage } from '../models/SearchProfilesPage'
import type { SearchRequest } from '../models/SearchRequest'
import type { Source } from '../models/Source'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ReportCreationService {
  /**
   * @returns any return id for new report
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postReportNew(): CancelablePromise<
    | {
        reportId: string
      }
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report/new',
      errors: {
        401: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param requestBody add profile to reports request
   * @returns AddResult response for add profile endpoint
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postReportAdd(
    reportId: string,
    source: Source,
    requestBody?: {
      id?: ProfileId
      unloaded?: boolean
    },
  ): CancelablePromise<AddResult | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report/{reportId}/{source}/add',
      path: {
        reportId: reportId,
        source: source,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `response for error`,
        403: `response for error`,
        404: `profile not found`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @returns string follows loading cancelled
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postReportCancel(
    reportId: string,
    source: Source,
  ): CancelablePromise<string | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report/{reportId}/{source}/cancel',
      path: {
        reportId: reportId,
        source: source,
      },
      errors: {
        401: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param limit items to get
   * @param requestBody search request body
   * @returns SearchProfilesPage list of matched profiles from source network
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postSearch(
    reportId: string,
    limit: number = 8,
    requestBody?: SearchRequest,
  ): CancelablePromise<Array<SearchProfilesPage> | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/search/{reportId}',
      path: {
        reportId: reportId,
      },
      query: {
        limit: limit,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `either \`source\` & \`username\` or \`name\` + \`age\` + \`state\` (\`age\`/\`state\` optionally) is required`,
        401: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param limit items to get
   * @param offset items to skip
   * @returns SearchProfilesPage page of list of matched profiles from source network
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getSearch(
    reportId: string,
    source: Source,
    limit: number = 8,
    offset?: number,
  ): CancelablePromise<SearchProfilesPage | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/search/{reportId}/{source}',
      path: {
        reportId: reportId,
        source: source,
      },
      query: {
        limit: limit,
        offset: offset,
      },
      errors: {
        400: `either \`source\` & \`username\` or \`name\` + \`age\` + \`state\` (\`age\`/\`state\` optionally) is required`,
        401: `response for error`,
        403: `response for error`,
      },
    })
  }
}
