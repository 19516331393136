/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddResult } from '../models/AddResult'
import type { FollowsCheck } from '../models/FollowsCheck'
import type { GeneralError } from '../models/GeneralError'
import type { InterestId } from '../models/InterestId'
import type { InterestProfiles } from '../models/InterestProfiles'
import type { ProfileId } from '../models/ProfileId'
import type { RedzoneCategory } from '../models/RedzoneCategory'
import type { ReportGeneratedCheck } from '../models/ReportGeneratedCheck'
import type { ReportItem } from '../models/ReportItem'
import type { ReportSingleInterest } from '../models/ReportSingleInterest'
import type { ReportSummary } from '../models/ReportSummary'
import type { SearchProfilesPage } from '../models/SearchProfilesPage'
import type { SearchRequest } from '../models/SearchRequest'
import type { SignInRequest } from '../models/SignInRequest'
import type { Source } from '../models/Source'
import type { SummaryCheck } from '../models/SummaryCheck'
import type { Token } from '../models/Token'
import type { User } from '../models/User'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class DemoApiService {
  /**
   * @param requestBody Sign in request
   * @returns Token JWT token
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postAuthSignin(
    requestBody?: SignInRequest,
  ): CancelablePromise<Token | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/signin',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @returns string OK
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postAuthSignout(): CancelablePromise<string | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/signout',
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @returns User user info
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getAuthInfo(): CancelablePromise<User | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/auth/info',
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @param source source of report
   * @returns RedzoneCategory list of all available interests
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getRedzoneInterests(
    source: Source,
  ): CancelablePromise<Array<RedzoneCategory> | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/interests/{source}/redzone',
      path: {
        source: source,
      },
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @returns any return id for new report
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postReportNew(): CancelablePromise<
    | {
        reportId: string
      }
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report/new',
      errors: {
        401: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param requestBody add profile to reports request
   * @returns AddResult response for add profile endpoint
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postReportAdd(
    reportId: string,
    source: Source,
    requestBody?: {
      id?: ProfileId
      unloaded?: boolean
    },
  ): CancelablePromise<AddResult | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report/{reportId}/{source}/add',
      path: {
        reportId: reportId,
        source: source,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `response for error`,
        403: `response for error`,
        404: `profile not found`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @returns string follows loading cancelled
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postReportCancel(
    reportId: string,
    source: Source,
  ): CancelablePromise<string | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report/{reportId}/{source}/cancel',
      path: {
        reportId: reportId,
        source: source,
      },
      errors: {
        401: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param limit items to get
   * @param requestBody search request body
   * @returns SearchProfilesPage list of matched profiles from source network
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postSearch(
    reportId: string,
    limit: number = 8,
    requestBody?: SearchRequest,
  ): CancelablePromise<Array<SearchProfilesPage> | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/search/{reportId}',
      path: {
        reportId: reportId,
      },
      query: {
        limit: limit,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `either \`source\` & \`username\` or \`name\` + \`age\` + \`state\` (\`age\`/\`state\` optionally) is required`,
        401: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param limit items to get
   * @param offset items to skip
   * @returns SearchProfilesPage page of list of matched profiles from source network
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getSearch(
    reportId: string,
    source: Source,
    limit: number = 8,
    offset?: number,
  ): CancelablePromise<SearchProfilesPage | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/search/{reportId}/{source}',
      path: {
        reportId: reportId,
        source: source,
      },
      query: {
        limit: limit,
        offset: offset,
      },
      errors: {
        400: `either \`source\` & \`username\` or \`name\` + \`age\` + \`state\` (\`age\`/\`state\` optionally) is required`,
        401: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param interestId id of interests
   * @param limit items to get
   * @returns ReportSingleInterest list of profiles on which interest was found and additional info
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReport(
    reportId: string,
    source: Source,
    interestId: InterestId,
    limit: number = 8,
  ): CancelablePromise<ReportSingleInterest | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}/{source}/{interestId}',
      path: {
        reportId: reportId,
        source: source,
        interestId: interestId,
      },
      query: {
        limit: limit,
      },
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param interestId id of interests
   * @param offset items to skip
   * @param limit items to get
   * @returns InterestProfiles page of profiles list on which interest was found
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReportProfiles(
    reportId: string,
    source: Source,
    interestId: InterestId,
    offset?: number,
    limit: number = 8,
  ): CancelablePromise<InterestProfiles | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}/{source}/{interestId}/profiles',
      path: {
        reportId: reportId,
        source: source,
        interestId: interestId,
      },
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param limit items to get
   * @returns any Array of interests with profiles based on preview
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReport1(
    reportId: string,
    limit: number = 8,
  ): CancelablePromise<
    | {
        profiles: Array<ReportItem>
        summary?: ReportSummary
      }
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}',
      path: {
        reportId: reportId,
      },
      query: {
        limit: limit,
      },
      errors: {
        400: `error on uncompleted report request`,
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param limit items to get
   * @returns ReportItem Interests with profiles based on preview for worksource
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReport2(
    reportId: string,
    source: Source,
    limit: number = 8,
  ): CancelablePromise<ReportItem | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}/{source}',
      path: {
        reportId: reportId,
        source: source,
      },
      query: {
        limit: limit,
      },
      errors: {
        400: `error on uncompleted report request`,
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @returns ReportSummary GPT Generated summary of report
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReportSummary(
    reportId: string,
  ): CancelablePromise<ReportSummary | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}/summary',
      path: {
        reportId: reportId,
      },
      errors: {
        400: `error on uncompleted report request`,
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @returns ReportGeneratedCheck Check if report is generated
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReportCheck(
    reportId: string,
  ): CancelablePromise<ReportGeneratedCheck | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}/check',
      path: {
        reportId: reportId,
      },
      errors: {
        400: `error on uncompleted report request`,
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param interestId id of interests
   * @param requestBody report issue on profiles with interests
   * @returns string issue reported
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postReportIssue(
    reportId: string,
    source: Source,
    interestId: InterestId,
    requestBody?: {
      profiles: Array<ProfileId>
    },
  ): CancelablePromise<string | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report/{reportId}/{source}/{interestId}/issue',
      path: {
        reportId: reportId,
        source: source,
        interestId: interestId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `response for error`,
        403: `response for error`,
        404: `report or interest not found`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @returns any A continuous stream of server-sent events.
   *
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getSse(
    reportId: string,
  ): CancelablePromise<(FollowsCheck | SummaryCheck) | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sse/{reportId}',
      path: {
        reportId: reportId,
      },
    })
  }
}
