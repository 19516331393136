import { RefObject, useEffect, useRef, useState } from 'react'

type TElementSize = [number, number]

type THook = TElementSize

const useElementResize = (elementRef?: RefObject<HTMLElement>): THook => {
  const docRef = useRef(
    typeof document !== 'undefined' ? document?.documentElement : null,
  )

  if (elementRef === undefined) {
    elementRef = docRef
  }
  const initSize: TElementSize = [
    elementRef.current?.clientWidth || 0,
    elementRef.current?.clientHeight || 0,
  ]
  const [elementSize, setElementSize] = useState<TElementSize>(initSize)

  useEffect(() => {
    const handleResize = (): void => {
      setElementSize([
        elementRef?.current?.clientWidth || 0,
        elementRef?.current?.clientHeight || 0,
      ])
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [elementRef])

  return elementSize
}

export default useElementResize
