import React from 'react'
import { useRouter } from 'next/router'
import { useMount } from 'react-use'

/**
 * Hook for tracking server side effects (getServertSideProps for example)
 * @returns flag of processing page loading
 */
const useServerPageLoading = () => {
  const { events } = useRouter()

  const [pageLoading, setPageLoading] = React.useState(false)

  useMount(() => {
    const start = () => {
      setPageLoading(true)
    }

    const end = () => {
      setPageLoading(false)
    }

    events.on('routeChangeStart', start)
    events.on('routeChangeComplete', end)
    events.on('routeChangeError', end)

    return () => {
      events.off('routeChangeStart', start)
      events.off('routeChangeComplete', end)
      events.off('routeChangeError', end)
    }
  })

  return pageLoading
}

export default useServerPageLoading
