import type { AppProps } from 'next/app'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { Slide, ToastContainer } from 'react-toastify'
import { Inter } from 'next/font/google'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { ErrorBoundary } from '@sentry/nextjs'
import { CrashFallback, Header, Loading } from '@/layouts'
import { AuthProvider } from '@/context'
// eslint-disable-next-line import/no-named-as-default
import useAnalytics from '@/lib/analytics/useAnalytics'

import commonEnTranslation from 'src/assets/locales/en/common.json'
import { isDev } from 'src/lib/runtime'

import 'src/styles/globals.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'src/lib/api-client/interceptors'
import React from 'react'
import { useServerPageLoading } from '@/lib/hooks'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      common: commonEnTranslation,
    },
  },
  lng: 'en',
  ns: ['common'],
  debug: isDev,
  returnNull: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

const inter = Inter({ subsets: ['latin'] })

const App = ({ Component, pageProps }: AppProps) => {
  useAnalytics()

  const isServerPageLoading = useServerPageLoading()

  return (
    <HelmetProvider>
      <>
        <Helmet>
          <html lang={i18n.language} />
          <title>Socialprofiler</title>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/favicon/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon/favicon-16x16.png"
          />
          <link
            rel="manifest"
            href="/site.webmanifest"
            crossOrigin="use-credentials"
          />
        </Helmet>
        <ErrorBoundary fallback={CrashFallback}>
          <ToastContainer
            position="top-center"
            transition={Slide}
            closeOnClick={false}
          />
          <AuthProvider>
            <main className={inter.className}>
              <Header />
              {isServerPageLoading ? <Loading /> : <Component {...pageProps} />}
            </main>
          </AuthProvider>
        </ErrorBoundary>
      </>
    </HelmetProvider>
  )
}

// noinspection JSUnusedGlobalSymbols
export default App
