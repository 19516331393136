import useErrorEffect from '../use-error-effect'
import { ReportCreationService, type Source } from '@/lib/api-client/generated'
import { getApiGeneralError, isGeneralError } from '@/utils/api'
import useCustomAsyncFn from './use-custom-async-fn'

/**
 * Service for request to search for profiles on a specific social network
 *
 * ReportCreationService.getSearch
 * useCustomAsyncFn implementation
 */
const useSearchProfilesBySourceService = (reportId: string) => {
  const state = useCustomAsyncFn(
    async ({
      source,
      limit,
      offset,
    }: {
      source: Source
      limit?: number
      offset?: number
    }) => {
      try {
        const result = await ReportCreationService.getSearch(
          reportId,
          source,
          limit || 16,
          offset,
        )

        if (isGeneralError(result)) {
          throw result
        }

        return result
      } catch (e) {
        throw getApiGeneralError("can't get profiles from the server", e)
      }
    },
    [],
  )

  useErrorEffect(state[0].error, true)

  return state
}

export default useSearchProfilesBySourceService
