'use client'

import React from 'react'
import { useMountedState } from 'react-use'
import { useTranslation } from 'react-i18next'
import { cx } from 'class-variance-authority'

import { useAuthContext } from '@/context'
import { Logo } from './Logo'

import styles from './Header.module.scss'
import { useCreatePageStatusSelector } from '@/stores'
import { useRouter } from 'next/router'

const Header = () => {
  const { t } = useTranslation()
  const [menuActive, setMenuActive] = React.useState(false)
  const { isAuthenticated, closeSession } = useAuthContext()

  const { pathname } = useRouter()

  const isMounted = useMountedState()

  const pageStatus = useCreatePageStatusSelector()

  const showLogo = pathname !== '/report/[id]/create' || pageStatus !== 'form'

  const handleLogout = () => {
    setMenuActive(false)
    closeSession()
  }

  return (
    <nav
      className={cx([
        'navbar',
        styles.navigation,
        { [styles.navigation__show]: !showLogo },
      ])}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <div>{showLogo && <Logo />}</div>

        {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
        <div
          role="button"
          className={cx([
            'navbar-burger',
            'navbar-end',
            { 'is-active': menuActive },
          ])}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => setMenuActive(!menuActive)}
        >
          <span aria-hidden />
          <span aria-hidden />
          <span aria-hidden />
        </div>
      </div>

      <div
        id="navbarBasicExample"
        className={cx(['navbar-menu', { 'is-active': menuActive }])}
      >
        {isAuthenticated && isMounted() && (
          <div className="navbar-start">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="navbar-item" onClick={handleLogout}>
              {t('navbarLogout')}
            </a>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Header
