import React from 'react'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import { useRouter } from 'next/navigation'

const SESSION_VERSION = 1

/**
 * Check for session token in cookie and stored version
 */
const isSessionActive = () => {
  const session = getCookie('session')
  const sessionVersion = getCookie('session_version')

  return !!(
    session &&
    sessionVersion &&
    Number(sessionVersion) === SESSION_VERSION
  )
}

type AuthContext = {
  isAuthenticated: boolean
  setSession: (token: string) => void
  closeSession: () => void
}

const initialValue = {
  isAuthenticated: false,
  setSession: () => null,
  closeSession: () => null,
}

const AuthContext = React.createContext<AuthContext>(initialValue)

export const useAuthContext = () => React.useContext(AuthContext)

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const router = useRouter()

  const setSession = React.useCallback((token: string) => {
    setCookie('session', token)
    setCookie('session_version', SESSION_VERSION)

    router.refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeSession = React.useCallback(() => {
    deleteCookie('session')
    deleteCookie('session_version')

    router.push('/login')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const value = React.useMemo(
    () => ({
      isAuthenticated: isSessionActive(),
      setSession,
      closeSession,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
