import useErrorEffect from '../use-error-effect'
import { ReportCreationService, type Source } from '@/lib/api-client/generated'
import { getApiGeneralError, isGeneralError } from '@/utils/api'
import useCustomAsyncFn from './use-custom-async-fn'

/**
 * Service for cancelling of follows loading
 *
 * ReportCreationService.postReportCancel
 * useCustomAsyncFn implementation
 */
const useCancelFollowsLoading = (reportId: string) => {
  const state = useCustomAsyncFn(async (source: Source) => {
    try {
      const result = await ReportCreationService.postReportCancel(
        reportId,
        source,
      )

      if (isGeneralError(result)) {
        throw result
      }

      return result
    } catch (e) {
      throw getApiGeneralError("can't cancel loading", e)
    }
  }, [])

  useErrorEffect(state[0].error, true)

  return state
}

export default useCancelFollowsLoading
