var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bOhRreTLrr7r8qx1ovSxt"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { isDev } from './src/lib/runtime'

Sentry.init({
  enabled: !isDev,
  dsn: 'https://602453aaedc64f43bc35bc4b0a577bc7@o4504622046904320.ingest.sentry.io/4504622061125632',
  tracesSampleRate: 1.0,
})
