import React, { ComponentPropsWithoutRef } from 'react'

import styles from './Checkbox.module.scss'

type CheckboxProps = ComponentPropsWithoutRef<'input'> & {
  onCheck?: () => void
  errors?: string
}

type InputRef = HTMLInputElement

const Checkbox = React.forwardRef<InputRef, CheckboxProps>((restProps, ref) => {
  const {
    className,
    checked: checkedRest = false,
    errors,
    onCheck,
    ...props
  } = restProps

  const handleCheck = () => {
    onCheck?.()
  }

  return (
    <label className={styles.switch} htmlFor="input">
      <input
        ref={ref}
        type="checkbox"
        id="input"
        onChange={handleCheck}
        checked={checkedRest}
        {...props}
      />
      <span className={styles.slider} />
    </label>
  )
})

Checkbox.displayName = 'Checkbox'

export default React.memo(Checkbox)
