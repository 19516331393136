/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeneralError } from '../models/GeneralError'
import type { SignInRequest } from '../models/SignInRequest'
import type { Token } from '../models/Token'
import type { User } from '../models/User'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class AuthService {
  /**
   * @param requestBody Sign in request
   * @returns Token JWT token
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postAuthSignin(
    requestBody?: SignInRequest,
  ): CancelablePromise<Token | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/signin',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @returns string OK
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postAuthSignout(): CancelablePromise<string | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/signout',
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @returns User user info
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getAuthInfo(): CancelablePromise<User | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/auth/info',
      errors: {
        401: `response for error`,
      },
    })
  }
}
