import React from 'react'
import { Source } from '../api-client/generated'
import usePasteFromClipboard from './use-paste-from-clipboard'

const usePasteInSource = (
  source: Source | null,
  onPaste: (value: string) => void,
) => {
  const onPasteCallback = React.useCallback(
    (value: string) => {
      /** Should not set value on paste if no source have selected. */
      if (!source) {
        return
      }

      onPaste(value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [source],
  )

  return usePasteFromClipboard(onPasteCallback)
}

export default usePasteInSource
