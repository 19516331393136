import { useTranslation } from 'react-i18next'
import { FallbackRender } from '@sentry/react/types/errorboundary'

const CrashFallback = ({ error }: Parameters<FallbackRender>[0]) => {
  const { t: te } = useTranslation('', { keyPrefix: 'errors' })

  return (
    <>
      <h3>{te('appCrashMessageHeader')}</h3>
      <p>{te('appCrashMessageText')}</p>
      <details style={{ whiteSpace: 'pre-wrap' }}>
        {error && error.toString()}
      </details>
      <button onClick={() => window.location.reload()} type="button">
        {te('refreshPageButton')}
      </button>
    </>
  )
}

export default CrashFallback
