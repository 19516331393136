/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeneralError } from '../models/GeneralError'
import type { InterestId } from '../models/InterestId'
import type { ProfileId } from '../models/ProfileId'
import type { Source } from '../models/Source'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class IssuesService {
  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param interestId id of interests
   * @param requestBody report issue on profiles with interests
   * @returns string issue reported
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postReportIssue(
    reportId: string,
    source: Source,
    interestId: InterestId,
    requestBody?: {
      profiles: Array<ProfileId>
    },
  ): CancelablePromise<string | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report/{reportId}/{source}/{interestId}/issue',
      path: {
        reportId: reportId,
        source: source,
        interestId: interestId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `response for error`,
        403: `response for error`,
        404: `report or interest not found`,
      },
    })
  }
}
