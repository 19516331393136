import React from 'react'
import { useRouter } from 'next/router'
import {
  type ReportMappedItem,
  useReportMapSelector,
  useReportStore,
} from '@/stores'
import useStoreHydration from '@/stores/use-store-hydration'
import { useGetReportService, type UseGetReportServiceValue } from './api'
import { buildReportMappedItem } from '@/utils/report'

interface UseReportReturn {
  report: ReportMappedItem | null
  loading: boolean
}

const useReport = (
  reportId: string,
  initialReport?: UseGetReportServiceValue,
): UseReportReturn => {
  const { push } = useRouter()

  const [storedReport, setStoredReport] = useReportMapSelector(reportId)

  const report: ReportMappedItem | undefined = initialReport
    ? buildReportMappedItem(initialReport)
    : storedReport

  const [state, getReport] = useGetReportService()

  const isHydrated = useStoreHydration(useReportStore)

  React.useEffect(() => {
    if (!isHydrated) {
      return
    }

    if (report && !storedReport) {
      setStoredReport(reportId, report)

      return
    }

    if (report && storedReport) {
      return
    }

    getReport(reportId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHydrated])

  /** If  executed a request to re-receive the report, then write it to the repository. */
  React.useEffect(() => {
    if (state.value && !storedReport) {
      setStoredReport(reportId, buildReportMappedItem(state.value))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value])

  /** Error check and step back */
  React.useEffect(() => {
    if (state.error) {
      push(`/report/${reportId}/create`)
    }
  }, [push, state.error, reportId])

  return {
    report: report || (state.value ? buildReportMappedItem(state.value) : null),
    loading: state.loading || !isHydrated,
  }
}

export default useReport
