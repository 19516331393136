/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-empty-object-type */

import { VariantProps, cva } from 'class-variance-authority'
import React from 'react'

import styles from './SpeedDial.module.scss'
import { Icon, IconNames } from '../Icon'
import { Button } from '../Button'

const speedDialStyle = cva(styles.speeddial, {
  variants: {
    animation: {
      'action-move': styles.speeddial_actionMove,
      'action-move-reverse': styles.speeddial_actionMoveReverse,
      'button-move': styles.speeddial_buttonMove,
    },
    open: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      animation: 'button-move',
      open: true,
      className: styles.speeddial_buttonMove_open,
    },
    {
      animation: 'button-move',
      open: false,
      className: styles.speeddial_buttonMove_close,
    },
    {
      animation: 'action-move',
      open: true,
      className: styles.speeddial_actionMove_open,
    },
    {
      animation: 'action-move',
      open: false,
      className: styles.speeddial_actionMove_close,
    },
    {
      animation: 'action-move-reverse',
      open: true,
      className: styles.speeddial_actionMoveReverse_open,
    },
  ],
  defaultVariants: { animation: 'action-move', open: false },
})

type SpeedDialStyleProps = VariantProps<typeof speedDialStyle>

type SpeedDialProps = SpeedDialStyleProps &
  React.ComponentPropsWithoutRef<'div'> &
  React.PropsWithChildren & {
    /**
     * The icon to display in the SpeedDial Icon on close.
     * Default Icon name is "arrowLeft".
     */
    nameCloseIcon?: IconNames

    /**
     * The icon to display in the SpeedDial Icon on open.
     * Default Icon name is "add".
     */
    nameOpenIcon?: IconNames

    speedDialTitle?: JSX.Element

    /**
     * Callback fired when the component requests to be closed.
     */
    onClose?: () => void
    /**
     * Callback fired when the component requests to be open.
     */
    onOpen?: () => void
    /**
     * If `true`, the component is shown on click in mobile device (730px).
     */
    open?: boolean

    /**
     * Animation of hiding child elements
     * [action-move] - the value passed to action - is shifted to the button when closing
     * [button-move] - when closing the action element, the button for opening the component is hidden, and the button for opening the component moves
     * [action-move-reverse] - the value passed to action - when closed is shifted to the - c button of the last element, while the very position of action reverse
     *  animation?: 'action-move' | 'button-move' | 'action-move-reverse'
    
    */
  }

const SpeedDial: React.FC<SpeedDialProps> = ({
  nameCloseIcon,
  nameOpenIcon,
  children,
  className,
  speedDialTitle,
  onClose,
  onOpen,
  open,
  animation,
}) => {
  return (
    <div className={speedDialStyle({ animation, className, open })}>
      <Button
        size="icon"
        type="button"
        className={styles.buttonOpen}
        onClick={onOpen}
      >
        <Icon
          name={nameOpenIcon ?? 'add'}
          width={24}
          height={24}
          className={styles.buttonOpen_icon}
        />
      </Button>

      <div className={styles.title}>
        <Button
          size="icon"
          type="button"
          className={styles.buttonClose}
          onClick={onClose}
        >
          <Icon
            name={nameCloseIcon ?? 'arrowLeft'}
            width={24}
            height={24}
            className={styles.icon}
          />
        </Button>

        {speedDialTitle}
      </div>

      <div className={styles.actions}>{children}</div>
    </div>
  )
}

export default SpeedDial
