import useErrorEffect from '../use-error-effect'
import {
  DataReceivingService,
  type InterestId,
  type Source,
} from '@/lib/api-client/generated'
import { getApiGeneralError, isGeneralError } from '@/utils/api'
import useCustomAsyncFn from './use-custom-async-fn'

/**
 * Service for obtaining a page for a specific interest from a specific report on a specific social network
 * with additional information about the interest itself
 *
 * DataReceivingService.getReport
 * useCustomAsyncFn implementation
 */
const useGetInterestService = () => {
  const state = useCustomAsyncFn(
    async (
      reportId: string,
      source: Source,
      interestId: InterestId,
      limit: number = 8,
    ) => {
      try {
        const result = await DataReceivingService.getReport(
          reportId,
          source,
          interestId,
          limit,
        )

        if (isGeneralError(result)) {
          throw result
        }

        return result
      } catch (e) {
        throw getApiGeneralError("can't get interest from the server", e)
      }
    },
    [],
  )

  useErrorEffect(state[0].error)

  return state
}

export default useGetInterestService
