import axios from 'axios'
import JSONbig from 'json-bigint'
import * as process from 'process'
import { getCookie } from 'cookies-next'

axios.defaults.timeout = 300000

axios.interceptors.request.use(
  (req) => {
    if (req.transitional) {
      req.transitional.silentJSONParsing = false
      req.transitional.forcedJSONParsing = false
    }

    /** Handling server requests */
    if (typeof window === 'undefined') {
      req.url = `${
        !req.url?.startsWith('http')
          ? process.env.NEXT_PUBLIC_API_SERVER_URL || 'http://localhost:3000'
          : ''
      }${req.url}`
    } else {
      const sessionToken = getCookie('session')

      if (sessionToken) {
        req.headers.Authorization = `Bearer ${sessionToken}`
      }
    }

    req.timeout = 300000

    req.transformRequest = (data) => {
      try {
        return JSONbig.stringify(data)
      } catch (e) {
        console.error(e)

        return data
      }
    }
    req.transformResponse = (data) => {
      try {
        return JSONbig.parse(data)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        // eslint-disable-next-line no-throw-literal
        throw { message: data }
      }
    }

    return req
  },
  (error) => {
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    /** Intercept onRejected callback for correct error handling in api hooks. */
    return Promise.reject(error?.response?.data ?? error)
  },
)
