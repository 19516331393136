import { useTranslation } from 'react-i18next'
import { Button } from '../Button'

type ToastProps = {
  error?: Error
  hideReload?: boolean
}

const Toast: React.FC<ToastProps> = ({ error, hideReload }) => {
  const { t: te } = useTranslation('', { keyPrefix: 'errors' })

  return (
    <div className="content">
      <strong>{te('toastTitle')}</strong>
      <p>
        {te('toastMessage', {
          errorMessage: error?.message || 'Unknown error',
        })}
      </p>
      {!hideReload && (
        <Button onClick={() => window.location.reload()} color="black">
          {te('toastReloadButton')}
        </Button>
      )}
    </div>
  )
}

export default Toast
