import useErrorEffect from '../use-error-effect'
import {
  ReportCreationService,
  SearchProfilesPage,
  type SearchRequest,
} from '@/lib/api-client/generated'
import { getApiGeneralError, isGeneralError } from '@/utils/api'
import useCustomAsyncFn from './use-custom-async-fn'
import useCancelablePromise from '../use-cancelable-promise'

/**
 * Service for request to search for profiles on all social networks
 *
 * ReportCreationService.postSearch
 * useCustomAsyncFn implementation
 */
const useSearchProfilesOnCreateService = (reportId: string) => {
  const [addToCancel, cancel] = useCancelablePromise<SearchProfilesPage[]>()

  const state = useCustomAsyncFn(
    async ({
      limit,
      requestBody,
    }: {
      limit?: number
      requestBody?: SearchRequest
    }) => {
      const request = addToCancel(
        ReportCreationService.postSearch(reportId, limit || 16, requestBody),
      )

      try {
        const result = await request

        if (isGeneralError(result)) {
          throw result
        }

        return result
      } catch (e) {
        if (request?.isCancelled) {
          return null
        }

        throw getApiGeneralError("can't get profiles from the server", e)
      }
    },
    [],
  )

  useErrorEffect(state[0].error)

  return [state[0], state[1], cancel] as const
}

export default useSearchProfilesOnCreateService
