import React from 'react'
import { captureException } from '@sentry/core'
import { toast } from 'react-toastify'
import { Toast } from '@/components'

const useErrorEffect = <T extends Error>(error?: T, hideReload?: boolean) =>
  React.useEffect(() => {
    if (error) {
      captureException(error)
      console.error(
        `${error.name}: ${error.message}${`\ncause: ${error.cause}`}\n${error.stack}`,
      )
      toast.error(<Toast error={error} hideReload={hideReload} />, {
        autoClose: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

export default useErrorEffect
