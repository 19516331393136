import { SourceMap } from '@/entities'
import type {
  RedzoneCategory,
  ReportItem,
  ReportSummaryResult,
  Source,
} from '@/lib/api-client/generated'

import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export type ReportMappedItem = {
  map: SourceMap<ReportItem>
  summary?: ReportSummaryResult
}

type ReportState = {
  redzoneCategorySourceMap: SourceMap<RedzoneCategory[]>
  reportMap: Record<string, ReportMappedItem>
}

type ReportActions = {
  setRedzoneCategorySourceMap: (
    source: Source,
    redzoneCategoryList: RedzoneCategory[],
  ) => void
  setReport: (reportId: string, mappedReport: ReportMappedItem) => void
  setSummary: (reportId: string, summary: ReportSummaryResult) => void
}

const initialState: ReportState = {
  redzoneCategorySourceMap: {},
  reportMap: {},
}

const useReportStore = create<ReportState & ReportActions>()(
  persist(
    (set) => ({
      ...initialState,

      setRedzoneCategorySourceMap: (source, redzoneCategoryList) =>
        set((state) => ({
          ...state,
          redzoneCategorySourceMap: {
            ...state.redzoneCategorySourceMap,
            [source]: redzoneCategoryList,
          },
        })),

      setReport: (reportId, mappedReport) =>
        set((state) => {
          const prevReports =
            Object.keys(state.reportMap).length >= 4
              ? {
                  ...Object.entries(state.reportMap).reduce(
                    (acc, [id, prevReport], index) =>
                      !index ? acc : { ...acc, [id]: prevReport },
                    {},
                  ),
                }
              : state.reportMap

          return {
            ...state,
            reportMap: {
              ...prevReports,
              [reportId]: mappedReport,
            },
          }
        }),

      setSummary: (reportId, summary) =>
        set((state) => ({
          ...state,
          reportMap: {
            ...state.reportMap,
            [reportId]: { ...state.reportMap[reportId], summary },
          },
        })),
    }),
    { name: 'report', skipHydration: true },
  ),
)

export default useReportStore
