/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeneralError } from '../models/GeneralError'
import type { HealthCheck } from '../models/HealthCheck'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class HealthCheckService {
  /**
   * @returns HealthCheck response of healtcheck
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getHealthcheck(): CancelablePromise<
    HealthCheck | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/healthcheck',
    })
  }
}
