import type { UseGetReportServiceValue } from '@/lib/hooks'
import type { ReportMappedItem } from '@/stores'
import { buildSourceMap } from './profile'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidReportId = (reportId: any): reportId is string =>
  typeof reportId === 'string' && reportId.length === 24

export const buildReportMappedItem = ({
  profiles,
  summary,
}: UseGetReportServiceValue): ReportMappedItem => ({
  map: buildSourceMap(profiles),
  summary: summary?.result,
})
