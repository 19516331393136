import React from 'react'
import { type Source } from '../api-client/generated'

const useSource = (initialSource?: Source) => {
  const [source, setSource] = React.useState<Source | null>(
    initialSource || null,
  )

  const set = React.useCallback((selected: Source | null) => {
    setSource(selected)
  }, [])

  return [source, set] as const
}

export default useSource
