import { DataReceivingService } from '@/lib/api-client/generated'
import { getApiGeneralError, isGeneralError } from '@/utils/api'
import useCustomAsyncFn from './use-custom-async-fn'

/**
 * Service
 *
 * DataReceivingService.getReportCheck
 * useCustomAsyncFn implementation
 */
const useGetReportCheckService = () => {
  const state = useCustomAsyncFn(async (reportId: string) => {
    try {
      const result = await DataReceivingService.getReportCheck(reportId)

      if (isGeneralError(result)) {
        throw result
      }

      return result
    } catch (e) {
      throw getApiGeneralError("can't get report check from the server", e)
    }
  }, [])

  return state
}

export default useGetReportCheckService
