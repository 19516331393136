import useErrorEffect from '../use-error-effect'
import { DataReceivingService } from '@/lib/api-client/generated'
import { getApiGeneralError, isGeneralError } from '@/utils/api'
import useCustomAsyncFn from './use-custom-async-fn'

/**
 * Service for receiving a report on all social networks
 *
 * DataReceivingService.getReport1
 * useCustomAsyncFn implementation
 */
const useGetReportService = () => {
  const state = useCustomAsyncFn(async (reportId: string, limit?: number) => {
    try {
      const result = await DataReceivingService.getReport1(reportId, limit)

      if (isGeneralError(result)) {
        throw result
      }

      return result
    } catch (e) {
      throw getApiGeneralError("can't get report from the server", e)
    }
  }, [])

  useErrorEffect(state[0].error)

  return state
}

/** Response of DataReceivingService.getReport1 */
export type UseGetReportServiceValue = NonNullable<
  ReturnType<typeof useGetReportService>[0]['value']
>

export default useGetReportService
