import type { GeneralError } from '@/lib/api-client/generated'
import { isObject } from './helper'

/** Type guard for General Error. */
export const isGeneralError = <T extends object | string>(
  response: T | GeneralError,
): response is GeneralError =>
  // @ts-ignore
  isObject(response) ? 'code' in response && 'message' in response : false

/** Error builder. */
export const getApiGeneralError = (message: string, error: unknown) => {
  if (!error || typeof error !== 'object') {
    return new Error(message)
  }

  if ('code' in error && 'message' in error) {
    return new Error(
      `${message}. Server error with code ${error.code}: ${error.message}.`,
    )
  }

  if ('code' in error) {
    return new Error(`${message}. Server error with code: ${error.code}.`)
  }

  if ('message' in error) {
    return new Error(`${message}. Server error with message: ${error.message}.`)
  }

  return new Error(message)
}

export const isFulfilledSettledResult = <T>(
  settledResult: PromiseSettledResult<T>,
): settledResult is PromiseFulfilledResult<T> =>
  settledResult.status === 'fulfilled' && 'value' in settledResult
