import React from 'react'
import { useMount } from 'react-use'

const useStoreHydration = <
  Store extends { persist: { rehydrate: () => void } },
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...storeList: any[]
) => {
  const [isHydrated, setIsHydrated] = React.useState(false)

  useMount(() => {
    storeList.forEach((store) => (store as Store).persist.rehydrate())
    setIsHydrated(true)
  })

  return isHydrated
}

export default useStoreHydration
