import useErrorEffect from '../use-error-effect'
import { DataReceivingService, type Source } from '@/lib/api-client/generated'
import { getApiGeneralError, isGeneralError } from '@/utils/api'
import useCustomAsyncFn from './use-custom-async-fn'

/**
 * Service for receiving a report on a specific social network
 *
 * DataReceivingService.getReport2
 * useCustomAsyncFn implementation
 */
const useGetReportBySourceService = () => {
  const state = useCustomAsyncFn(
    async (reportId: string, source: Source, limit: number = 8) => {
      try {
        const result = await DataReceivingService.getReport2(
          reportId,
          source,
          limit,
        )

        if (isGeneralError(result)) {
          throw result
        }

        return result
      } catch (e) {
        throw getApiGeneralError("can't get report from the server", e)
      }
    },
    [],
  )

  useErrorEffect(state[0].error)

  return state
}

export default useGetReportBySourceService
