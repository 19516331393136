import useErrorEffect from '../use-error-effect'
import { DataReceivingService, type Source } from '@/lib/api-client/generated'
import { getApiGeneralError, isGeneralError } from '@/utils/api'
import useCustomAsyncFn from './use-custom-async-fn'

/**
 * Service
 *
 * DataReceivingService.getRedzoneInterests
 * useCustomAsyncFn implementation
 */
const useGetRedzoneInterestsService = () => {
  const state = useCustomAsyncFn(async (source: Source) => {
    try {
      const result = await DataReceivingService.getRedzoneInterests(source)

      if (isGeneralError(result)) {
        throw result
      }

      return result
    } catch (e) {
      throw getApiGeneralError("can't get interests from the server", e)
    }
  }, [])

  useErrorEffect(state[0].error, true)

  return state
}

/** Response of DataReceivingService.getRedzoneInterests */
export type UseGetRedzoneInterestsServiceValue = NonNullable<
  ReturnType<typeof useGetRedzoneInterestsService>[0]['value']
>

export default useGetRedzoneInterestsService
