import { OpenAPI, type SSEMessageType } from '@/lib/api-client/generated'
import React from 'react'
import { SSEProvider, useSSE } from 'react-hooks-sse'
import type { SSEMessageMap, SSEMessageMapKey } from './models'

const SSE_URL = (reportId: string) =>
  `${process.env.NEXT_PUBLIC_API_SERVER_URL}${OpenAPI.BASE}/sse/${reportId}`

interface SSEListenerComponentProps<T extends SSEMessageMapKey> {
  event: T
  onMessage: (message: SSEMessageMap[T] | null) => void
}

const SSEListenerComponent = <T extends SSEMessageMapKey>({
  event,
  onMessage,
}: SSEListenerComponentProps<T>) => {
  const message = useSSE<SSEMessageMap[T] | null>(event, null)

  React.useEffect(() => {
    onMessage(message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])

  return null
}

interface SSEListenerProps {
  reportId: string
  messageList: SSEMessageType[]
  onMessage: <T extends SSEMessageMapKey>(
    event: T,
  ) => (message: SSEMessageMap[T] | null) => void
}

export default function SSEListener({
  reportId,
  messageList,
  onMessage,
}: SSEListenerProps) {
  return (
    <SSEProvider
      source={() =>
        new EventSource(SSE_URL(reportId), {
          withCredentials: true,
        })
      }
    >
      {messageList.map((event) => (
        <SSEListenerComponent
          key={event}
          event={event}
          onMessage={onMessage(event)}
        />
      ))}
    </SSEProvider>
  )
}
