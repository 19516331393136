import useErrorEffect from '../use-error-effect'
import { AuthService } from '@/lib/api-client/generated'
import { getApiGeneralError, isGeneralError } from '@/utils/api'
import useCustomAsyncFn from './use-custom-async-fn'

/**
 * Service
 *
 * AuthService.postAuthSignin
 * useCustomAsyncFn implementation
 */
const useAuthSignInService = () => {
  const state = useCustomAsyncFn(async (username: string, password: string) => {
    try {
      const result = await AuthService.postAuthSignin({ username, password })

      if (isGeneralError(result)) {
        throw result
      }

      return result
    } catch (e) {
      throw getApiGeneralError("can't sign in.", e)
    }
  }, [])

  useErrorEffect(state[0].error)

  return state
}

export default useAuthSignInService
