import { SourceMap } from '@/entities'
import type {
  ProfileBadReason,
  ProfileId,
  SearchProfilesPage,
  SearchRequest,
  Source,
} from '@/lib/api-client/generated'
import { buildSourceMap } from '@/utils/profile'

import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type CreatePageFormStatus = 'form' | 'list'

type SetProfileBadReasonConfirm = (dto: {
  source: Source
  value: ProfileBadReason | false
  id?: ProfileId | null
}) => void

type GetProfileBadResonConfirm = (dto: {
  id: ProfileId
  source: Source
}) => ProfileBadReason | false | undefined

export type BadReasonConfirmMap = SourceMap<{
  [id: ProfileId]: ProfileBadReason | false
}>

type SearchProfilesStateForm = {
  searchRequest: SearchRequest
  source?: Source
  link?: string
}

type SearchProfilesState = {
  badReasonConfirmMap: BadReasonConfirmMap
  profileSourceMap: SourceMap
  incompleteReportId: string | null
  pageStatus: CreatePageFormStatus
  form: Partial<SearchProfilesStateForm>
}

type SearchProfilesActions = {
  setSearchRequest: (searchRequest: SearchRequest) => void
  setSearchLink: (link?: string) => void
  setProfileSourceMap: (searchProfilePageList: SearchProfilesPage[]) => void
  setSingleProfileSourceMap: (searchProfilePage: SearchProfilesPage) => void
  resetFormState: () => void
  setIncompleteReportId: (incompleteReportId: string | null) => void
  setSearchFormStatus: (pageStatus: CreatePageFormStatus) => void
  setProfileBadReasonConfirm: SetProfileBadReasonConfirm
  getProfileBadResonConfirm: GetProfileBadResonConfirm
  setSearchSource: (source: Source) => void
  reset: () => void
}

const initialState: SearchProfilesState = {
  badReasonConfirmMap: {},
  profileSourceMap: {},
  pageStatus: 'form',
  incompleteReportId: null,
  form: {},
}

const useCreateReportStore = create<
  SearchProfilesState & SearchProfilesActions
>()(
  persist(
    (set, get) => ({
      ...initialState,

      reset: () => set(() => initialState),

      setSearchFormStatus: (pageStatus) =>
        set((state) => ({ ...state, pageStatus })),

      setSearchSource: (source) =>
        set((state) => ({ ...state, form: { ...state.form, source } })),

      setSearchRequest: (searchRequest) =>
        set((state) => ({ ...state, form: { ...state.form, searchRequest } })),

      setSearchLink: (link) =>
        set((state) => ({ ...state, form: { ...state.form, link } })),

      setIncompleteReportId: (incompleteReportId) =>
        set((state) => ({ ...state, incompleteReportId })),

      getProfileBadResonConfirm: (dto) =>
        get().badReasonConfirmMap[dto.source]?.[dto.id],

      setProfileBadReasonConfirm: (dto) =>
        set((state) => {
          if (!dto.id) {
            return state
          }

          return {
            ...state,
            badReasonConfirmMap: {
              ...state.badReasonConfirmMap,
              [dto.source]: {
                ...state.badReasonConfirmMap[dto.source],
                [dto.id]: dto.value,
              },
            },
          }
        }),

      setProfileSourceMap: (searchProfilePageList) =>
        set((state) => ({
          ...state,
          profileSourceMap: {
            ...state.profileSourceMap,
            ...buildSourceMap(searchProfilePageList),
          },
        })),

      setSingleProfileSourceMap: (searchProfilePage) =>
        set((state) => ({
          ...state,
          profileSourceMap: {
            ...state.profileSourceMap,
            [searchProfilePage.source]: searchProfilePage,
          },
        })),

      resetFormState: () =>
        set((state) => ({ ...state, profileSourceMap: {}, form: {} })),
    }),
    { name: 'create-report', skipHydration: true },
  ),
)

export default useCreateReportStore
