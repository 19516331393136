import { t } from 'i18next'
import { Helmet } from 'react-helmet-async'
import { cx } from 'class-variance-authority'

import styles from './Loading.module.scss'

const Loading = () => {
  return (
    <div className={cx(['loading', styles.loading])}>
      <Helmet>
        <title>{`Socialprofiler ${t('Loading...')}`}</title>
      </Helmet>
      <div className={styles.ring}>
        <div />
        <div />
        <div />
        <div />
      </div>
      <div className="loading-text">{t('Loading...')}</div>
    </div>
  )
}

export default Loading
