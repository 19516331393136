import { useMedia } from 'react-use'

interface UseMediaQuery {
  minWidth?: number
  maxWidth?: number
}

const useMediaQuery = ({ maxWidth, minWidth }: UseMediaQuery) =>
  useMedia(
    `${minWidth ? `(min-width: ${minWidth}px)` : ''}${minWidth && maxWidth ? ' and ' : ''}${maxWidth ? `(max-width: ${maxWidth}px)` : ''}`,
  )

export default useMediaQuery
