import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { cx } from 'class-variance-authority'
import { useTranslation } from 'react-i18next'

import logo from 'src/assets/images/logo.svg'

import styles from './Logo.module.scss'
import { useCreateReportProfilesSelector } from '@/stores'

const Logo = () => {
  const { t } = useTranslation()

  const { setSearchFormStatus } = useCreateReportProfilesSelector()

  const handleBackToForm = () => setSearchFormStatus('form')

  return (
    <Link
      className={cx(['navbar-item', styles.link])}
      href="/report/new"
      onClick={handleBackToForm}
    >
      <Image
        className={styles.logo}
        src={logo}
        alt={t('logoText')}
        style={{ maxHeight: 'unset' }}
        priority
      />
    </Link>
  )
}

export default Logo
