import useCreateReportStore from './store'

export const useBadReasonConfirmMapSelector = () =>
  useCreateReportStore((store) => store.badReasonConfirmMap)

export const useSetProfileBadReasonConfirmSelector = () =>
  useCreateReportStore((state) => state.setProfileBadReasonConfirm)

export const useSetProfileSourceMapSelector = () =>
  useCreateReportStore((state) => state.setProfileSourceMap)

export const useCreatePageStatusSelector = () =>
  useCreateReportStore((state) => state.pageStatus)

export const useResetCreatePageSelector = () =>
  useCreateReportStore((state) => state.reset)

export const useIncompleteReportIdSelector = () =>
  useCreateReportStore(
    (state) => [state.incompleteReportId, state.setIncompleteReportId] as const,
  )

export const useSearchSourceSelector = () =>
  useCreateReportStore(
    (state) => [state.form.source, state.setSearchSource] as const,
  )

export const useSearchRequestSelector = () =>
  useCreateReportStore((state) => ({
    form: state.form,
    setSearchRequest: state.setSearchRequest,
    setSearchLink: state.setSearchLink,
  }))

export const useCreateReportProfilesSelector = () =>
  useCreateReportStore((state) => ({
    profileSourceMap: state.profileSourceMap,
    isEmptyProfiles: !Object.keys(state.profileSourceMap).length,
    resetFormState: state.resetFormState,
    setSearchFormStatus: state.setSearchFormStatus,
    pageStatus: state.pageStatus,
  }))

export const useSearchFormActionSelector = () =>
  useCreateReportStore((state) => ({
    setProfileSourceMap: state.setProfileSourceMap,
    setSearchFormStatus: state.setSearchFormStatus,
  }))
