import useErrorEffect from '../use-error-effect'
import {
  IssuesService,
  type Source,
  type ProfileId,
  type InterestId,
} from '@/lib/api-client/generated'
import { getApiGeneralError, isGeneralError } from '@/utils/api'
import useCustomAsyncFn from './use-custom-async-fn'

/**
 * Service
 *
 * IssuesService.postReportIssue
 * useCustomAsyncFn implementation
 */
const useSendInterestIssueReportService = (
  reportId: string,
  interestId: InterestId,
  source: Source,
) => {
  const state = useCustomAsyncFn(async (profiles: ProfileId[]) => {
    try {
      const result = await IssuesService.postReportIssue(
        reportId,
        source,
        interestId,
        { profiles },
      )

      if (isGeneralError(result)) {
        throw result
      }

      return result
    } catch (e) {
      throw getApiGeneralError("can't post issue to the server", e)
    }
  }, [])

  useErrorEffect(state[0].error, true)

  return state
}

export default useSendInterestIssueReportService
