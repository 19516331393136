import useErrorEffect from '../use-error-effect'
import { DataReceivingService } from '@/lib/api-client/generated'
import { getApiGeneralError, isGeneralError } from '@/utils/api'
import useCustomAsyncFn from './use-custom-async-fn'

/**
 * Service
 *
 * DataReceivingService.getReportSummary
 * useCustomAsyncFn implementation
 */
const useGetReportSummaryService = (reportId: string) => {
  const state = useCustomAsyncFn(async () => {
    try {
      const result = await DataReceivingService.getReportSummary(reportId)

      if (isGeneralError(result)) {
        throw result
      }

      return result
    } catch (e) {
      throw getApiGeneralError("can't get report summary from the server", e)
    }
  }, [])

  useErrorEffect(state[0].error)

  return state
}

/** Response of DataReceivingService.getReportSummary */
export type useGetReportSummaryServiceValue = NonNullable<
  ReturnType<typeof useGetReportSummaryService>[0]['value']
>

export default useGetReportSummaryService
