import { VariantProps, cva } from 'class-variance-authority'
import styles from './Button.module.scss'
import React from 'react'

const buttonStyle = cva(styles.button, {
  variants: {
    variant: {
      primary: 'button',
    },
    color: {
      primary: styles.button_colorPrimary,
      black: ['is-black', styles.button_colorBlack],
      white: 'is-white',
      caribbean: 'is-primary',
      grey: styles.button_colorGrey,
    },
    size: {
      lg: styles.button_sizeLg,
      m: styles.button_sizeM,
      icon: styles.button_sizeIcon,
      iconSm: styles.button_sizeIconSm,
    },
  },
  defaultVariants: { variant: 'primary', color: 'primary', size: 'lg' },
})

type ButtonStyleProps = VariantProps<typeof buttonStyle> & {
  disabled?: boolean
  asChild?: boolean
  isLoading?: boolean
}

type ButtonProps = React.ComponentPropsWithoutRef<'button'> & ButtonStyleProps

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (restProps, ref) => {
    const { className, children, color, variant, size, ...props } = restProps

    return (
      // eslint-disable-next-line react/button-has-type
      <button
        ref={ref}
        className={buttonStyle({ color, size, variant, className })}
        {...props}
      >
        {children}
      </button>
    )
  },
)

Button.displayName = 'Button'

export default React.memo(Button)
