import React from 'react'
import styles from './Input.module.scss'
import { cx } from 'class-variance-authority'
import { Icon, IconNames } from '../Icon'

type InputProps = React.ComponentPropsWithoutRef<'input'> & {
  label: string
  placeholder: string
  errors?: string
  iconName?: IconNames
}

type InputRef = HTMLInputElement

const Input = React.forwardRef<InputRef, InputProps>((restProps, ref) => {
  const {
    iconName,
    className,
    required,
    placeholder,
    onChange,
    value,
    label,
    errors,
    ...props
  } = restProps
  const id = React.useId()

  return (
    <div className={cx([styles.field, className])}>
      <label
        className={cx([
          'label',
          styles.label,
          { [styles.label__icon]: iconName },
        ])}
        htmlFor={id}
      >
        <span>{label}</span>
      </label>
      <div
        className={cx([
          'control',
          styles.control,
          { 'has-icons-left has-icons-right': iconName },
        ])}
      >
        <div className={styles.background}>
          <input
            id={id}
            className={cx(['input', styles.input])}
            value={value}
            onChange={onChange}
            ref={ref}
            placeholder={placeholder}
            {...props}
          />
          {iconName && (
            <span className="icon is-left is-large">
              <Icon name={iconName} width={24} height={24} />
            </span>
          )}
        </div>
        {errors && (
          <div className={styles.error}>
            <p>{errors}</p>
          </div>
        )}
      </div>
    </div>
  )
})

Input.displayName = 'Input'

export default React.memo(Input)
