import type { FollowsCheck, SummaryCheck } from '@/lib/api-client/generated'
import type { SSEMessageMapValue } from './models'

export const isFollowsCheck = (
  message: SSEMessageMapValue | null,
): message is FollowsCheck => !!message && 'id' in message

export const isSummaryCheck = (
  message: SSEMessageMapValue | null,
): message is SummaryCheck => !!message && 'result' in message
