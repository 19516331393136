import React from 'react'
import { useSendInterestIssueReportService } from './api'
import type { InterestId, ProfileId, Source } from '../api-client/generated'

const useSendReportIssue = (
  reportId: string,
  interestId: InterestId,
  source: Source,
  onResult: () => void,
) => {
  const [state, sendReport] = useSendInterestIssueReportService(
    reportId,
    interestId,
    source,
  )

  const [isResultDelay, setIsResultDelay] = React.useState(false)

  const handleSendReport = React.useCallback(
    (profiles: ProfileId[]) => async () => {
      await sendReport(profiles)

      setIsResultDelay(true)

      setTimeout(() => {
        setIsResultDelay(false)
        onResult()
      }, 3000)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return {
    sendReport: handleSendReport,
    loading: state.loading,
    error: state.error,
    isResultDelay,
  }
}

export default useSendReportIssue
